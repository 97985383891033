import React from 'react'
import Layout from '../components/layout'
import Menu from '../components/Menu'
import Footer from '../components/Footer'
import LogosList from '../components/Home/LogosList'
import TopHome from '../components/Home/Top'
import ProductCatalog from '../components/Home/ProductCatalog'
import ServicesSection from '../components/Home/ServicesSection'
import Brand from '../components/Home/Brand'
import ProcessSection from '../components/Home/ProcessSection'
import Title from '../components/Share/Title'
import Header from '../components/Header'
import LeadImage from '../components/Home/LeadImage'
import Logos from '../config/logos.json'

const IndexPage = () => {
  return (
    <Layout>
      <Header page="index" />

      <Menu />

      <TopHome />

      <div className="md:bg-white md:z-10 md:relative ">
        <div className="pt-6 container mx-auto">
          <div className="pl-8 pr-8 mb-10 md:float-right md:w-1/2 md:mt-10 ">
            <div className="lg:w-1/2 lg:ml-20">
              <h3 className="font-semibold leading-tight text-4xl mb-4 pt-6">500+</h3>
              <p className="leading-loose">
                ZUFRIEDENE KUNDEN MANAGEN IHR BÜRO ERFOLGREICH MIT LENDIS
              </p>
            </div>
          </div>

          <div className="w-full">
            <div>
              <div className="pr-10 ">
                <Title
                  topMargin={false}
                  title={false}
                  right
                  header="IN GUTER GESELLSCHAFT"
                  titleSize=""
                />
              </div>
            </div>
            <LogosList logos={Logos} widthCount={7} />
          </div>
        </div>
      </div>

      <Brand />

      <div className="bg-white container mx-auto px-10">
        <Title title="Die Office Management Lösung" header="Lendis" titleSize="text-3xl" />
      </div>

      <div className="bg-white container mx-auto">
        <LeadImage />
      </div>

      <div className="bg-white container mx-auto px-10">
        <Title title="Unsere Produkte" header="Produkte" right titleSize="text-3xl" />
      </div>

      <ProductCatalog />

      <div className="bg-white container mx-auto px-10">
        <Title
          title="Weitere Software, Services & Benefits"
          header="SERVICES"
          right
          titleSize="text-3xl"
        />
      </div>

      <ServicesSection />

      <ProcessSection />

      <div className="bg-lendis-black">
        <Footer />
      </div>
    </Layout>
  )
}

export default IndexPage
