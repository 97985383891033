import React, { useState } from 'react'
import classnames from 'classnames'
import CheckBox from './Share/CheckBox'
import GoogleTagManagerService from '../services/GoogleTagManagerService'
import Cookies from 'js-cookie'

const MainContactForm = ({ formTarget }) => {
  const [email, setEmail] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState(false)
  const [agb, setAgb] = useState(false)
  const [openNewsletter, setOpenNewsletter] = useState(false)
  const gtm = new GoogleTagManagerService()
  let message = 'Ihre Anfrage wurde erfolgreich verschickt!'
  if (!isSubmissionSuccessful) {
    message = 'Fehler beim Versenden. Bitte versuchen Sie es später noch einmal!'
  }

  const onSubmit = async e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const url = window.location.href
    const referrer = document.referrer
    const utmParams = {
      utm_source: Cookies.get('utm_source') || null,
      utm_medium: Cookies.get('utm_medium') || null,
      utm_campaign: Cookies.get('utm_campaign') || null,
      utm_content: Cookies.get('utm_content') || null,
      utm_term: Cookies.get('utm_term') || null,
      gclid: Cookies.get('gclid') || null,
      adgroupid: Cookies.get('adgroupid') || null,
    }
    const payload = { email, url, ...utmParams, referrer, agb, openNewsletter }

    for (let [key, value] of formData.entries()) {
      payload[key] = value
    }
    setIsSubmitting(true)
    fetch(e.target.action, {
      method: 'POST',
      body: JSON.stringify(payload),
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        setIsSubmitted(true)
        if (res.ok) {
          gtm.fireMQLEvent()
          setIsSubmissionSuccessful(true)
        } else {
          setIsSubmissionSuccessful(false)
        }
      })
      .catch(_ => {
        setIsSubmitted(true)
        setIsSubmissionSuccessful(false)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const onEmailChange = e => {
    setEmail(e.target.value)
  }

  const handleCheckBox = e => {
    setAgb(!agb)
  }

  const handleOpenNewsletterCheckBox = async e => {
    setOpenNewsletter(!openNewsletter)
  }

  return (
    <div className="mt-4">
      <form
        className={`transition-ease-2 flex flex-wrap ${
          isSubmitted && isSubmissionSuccessful === true ? 'opacity-0 hidden' : 'opacity-100'
        }`}
        action={formTarget}
        onSubmit={onSubmit}
        method="post"
      >
        <div className="w-full flex">
          <div className="w-full">
            <input
              disabled={isSubmitting}
              placeholder="Geschäftliche E-Mail-Adresse"
              title="Bitte geben Sie eine gültige E-Mail-Adresse an."
              pattern="^([a-zA-Z0-9+_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
              type="text"
              className="h-12 mb-4 p-4 w-full py-3 "
              value={email}
              required
              onChange={onEmailChange}
            />
          </div>
          <button
            disabled={!agb || isSubmitting}
            type="submit"
            className={`${
              agb ? 'bg-lendis-main cursor-pointer' : 'cursor-not-allowed bg-lendis-back-gray'
            }  transition-ease-2 h-12 text-white w-16 pl-4 rounded-r `}
            value="->"
          >
            <svg
              width="31"
              height="15"
              viewBox="0 0 31 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.6508 8.25508H0.35498V6.54508H25.6508L22.8354 3.72966L24.0446 2.52051L28.9241 7.40008L24.0446 12.2797L22.8354 11.0705L25.6508 8.25508Z"
                fill="#ffffff"
              />
            </svg>
          </button>

          <input type="hidden" name="form_id" value="home_lead_form" />
          <input type="hidden" name="form_name" value="Home Lead form" />
          <input type="hidden" name="activeCampaignListId" value="3" />
          <input type="hidden" name="ac_field_vertical" value="Furniture" />
          <input type="hidden" name="ac_field_category" value="Möbel" />
          <input type="hidden" name="ac_field_product" value="Produkte" />
          <input
            type="hidden"
            name="ac_field_cataloglink"
            value="https://lendis.link/katalog-highlights"
          />
          <input type="hidden" name="ac_tag_product" value="Highlights" />
        </div>
        <div className="w-full">
          <CheckBox
            labelFont={'.65rem'}
            Label={
              'Ich bin damit einverstanden, dass die Lendis GmbH mir Informationen zu ihrem Produktsortiment per E-Mail zuschickt.'
            }
            Value={agb}
            Required={true}
            OnChange={e => handleCheckBox(e)}
          />
        </div>
        <div className="w-full">
          <CheckBox
            labelFont={'.65rem'}
            smallLabel={true}
            Label={
              'Ja, ich möchte <b>regelmäßig exklusive Rabatte, Tipps und Fachartikel</b> zu Büroausstattung per E-Mail erhalten.'
            }
            value={openNewsletter}
            OnChange={e => handleOpenNewsletterCheckBox(e)}
          />
        </div>
        <div className="w-full">
          <div style={{ fontSize: '.65rem' }}>
            Mir ist bewusst, dass ich meine Einwilligung gegenüber der Lendis GmbH jederzeit
            widerrufen kann.
          </div>
        </div>
      </form>
      <div className="w-full">
        <p
          className={`font-semibold ${classnames({
            'text-red-500': isSubmitted && isSubmissionSuccessful === false,
            'text-black': isSubmitted && isSubmissionSuccessful === true,
            hidden: !isSubmitted,
          })}`}
        >
          {message}
        </p>
      </div>
    </div>
  )
}

export default MainContactForm
