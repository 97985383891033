import React from 'react'
import Image from '../Lendis/Image'

const LogosList = ({ logos, widthCount }) => {
  return (
    <div className="flex flex-wrap items-center justify-center pt-6 mx-12 mb-16 md:mx-0">
      {logos.map((item, idx) => {
        return (
          <div key={idx} className={`container py-2 lg:px-2 px-2 w-1/4 lg:w-1/${widthCount}`}>
            <Image
              src={item['Image']}
              fixSize={item['Size']}
              className={`mx-auto ${item['className'] !== undefined && item['className']}`}
              alt={item['Alt']}
              realSVG={item['realSVG']}
            />
          </div>
        )
      })}
    </div>
  )
}

export default LogosList
