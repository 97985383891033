import React from 'react'
import Image from '../Lendis/Image'

const Brand = () => {
  return (
    <div className="bg-lendis-black pt-4 pb-4 ">
      <h3 className="font-semibold leading-loose text-3xl text-center text-white">Bekannt aus</h3>

      <div className="container flex flex-wrap items-center justify-center mx-auto px-16 sm:px-20 md:px-20 lg:px-32 xl:px-48 xl:container xl:mx-auto">
        <div className="w-1/2 p-2 px-4 md:w-1/4 xl:p-10 flex-grow-0">
          <Image src="website/br_011_tg3bop.png" noChange alt="brand-1" />
        </div>
        <div className="w-1/2 p-2 px-4 md:w-1/4 xl:p-10 flex-grow-0">
          <Image src="website/br_012_csgyro.png" noChange alt="brand-1" />
        </div>
        <div className="w-1/2 p-2 px-4 md:w-1/4 xl:p-10 flex-grow-0">
          <Image src="website/br_013_llyu8o.png" noChange alt="brand-3" />
        </div>
        <div className="w-1/2 p-2 px-4 md:w-1/4 xl:p-10 flex-grow-0">
          <Image src="website/br_014_apo2le.png" noChange alt="brand-4" />
        </div>
      </div>
    </div>
  )
}

export default Brand
