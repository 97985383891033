import React from 'react'
import { Link } from 'gatsby'
import Button from '../Share/Button'
import homeProduct from '../../config/home-product'
import Image from '../Lendis/Image'

const ProductCatalog = () => {
  return (
    <div className="lg:mb-32">
      {homeProduct.map((item, idx) => {
        const itemHref = item['Link'].replace(/\/+$/g, '') + '/'
        return (
          <div key={idx} id={item['ID']} className="container lg:flex mx-auto lg:mb-2">
            <div className="lg:w-1/2">
              <Image fixSize="640" src={item['Image']} alt={item['Alt']} />
            </div>
            <div className="lg:mb-0 lg:mt-0 lg:pt-8 lg:w-1/2 m-6 pb-8 pt-12 px-6">
              <div>
                <div className="flex items-center mb-12">
                  <p className="mr-4 text-lendis-main">{item['Number']}</p>
                  <hr className="border-t-2 w-20" />
                </div>
              </div>
              <h4 className="font-medium my-6 text-3xl">{item['Title']}</h4>
              <p className="leading-loose text-lg">{item['Description']}</p>
              <Link to={itemHref}>
                <Button arrow={true} text="Mehr Informationen" />
              </Link>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ProductCatalog
