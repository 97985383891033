import React from 'react'

import { Link } from 'gatsby'
import Button from '../Share/Button'
import Image from '../Lendis/Image'

const ServicesSection = () => {
  return (
    <div className="container mx-auto lg:flex lg:flex-wrap lg:flex-row-reverse lg:mb-20">
      <div className="bg-lendis-light-gray m-6 pb-8 pt-16 px-6 lg:w-5/12 lg:mx-0 lg:px-20">
        <div className="mb-12">
          <div className="py-2">
            <h4 className="text-2xl text-center">Willkommen in Ihrem Büro</h4>
          </div>
          <div className="flex items-center justify-center">
            <div className="mx-2">
              <Image src="website/LendisDesign_yqi2vt.svg" noChange={true} alt="LendisDesign" />
            </div>
            <div className="mx-2">
              <Image src="website/LendisLogo_vf4d0e.svg" noChange={true} alt="lendis logo" />
            </div>
          </div>
        </div>
        <div>
          <p className="leading-loose text-lg">
            Lendis bietet Ihnen neben dem Standardsortiment auch weitere Software, Services &
            Benefits. Buchen Sie ganz einfach Office-Software, Antivirus, Mobilitätsbudgets,
            Catering oder Sport-Mitgliedschaften über Lendis.
          </p>
        </div>
        <div className="m-auto w-64 mt-8">
          <Link to="/services/">
            <Button text="Jetzt informieren" green wFull className="mt-6" />
          </Link>
        </div>
      </div>

      <div className="pb-8 lg:w-7/12">
        <Image
          src="website/logos-centered_nui7jr.png"
          size="def:full lg:1/2"
          alt="logos-centered"
        />
      </div>
    </div>
  )
}

export default ServicesSection
