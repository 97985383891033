import React from 'react'
import Image from '../Lendis/Image'

const SquareBox = props => {
  const {
    title,
    description,
    image,
    imageSize = 50,
    width = 3,
    className = '',
    numberTitle = false,
  } = props
  return (
    <div className={`lg:w-1/${width} ${className}`}>
      <div className="bg-lendis-light-gray m-6 pb-8 pt-12 px-8 h-full ">
        {numberTitle && (
          <div>
            <div className="flex items-center mb-12">
              <p className="mr-4 text-lendis-main">{numberTitle}</p>
              <hr className="border-t-2 w-20" />
            </div>
          </div>
        )}
        <div className="flex my-6">
          <Image src={image} fixSize={imageSize} alt={title} />
          <h4 className="ml-4 font-medium text-3xl">{title}</h4>
        </div>
        <p className="leading-loose text-lg">{description}</p>
      </div>
    </div>
  )
}

export default SquareBox
