import React from 'react'
import SquareSection from '../Share/SquareSection'

const ProcessSection = () => {
  const process_image = process.env.CLOUDINARY_URL + '/f_auto/website/2_1920px_DSC5520_kj2hce.jpg'

  return (
    <SquareSection
      smallTitle="PROZESS"
      title="So funktioniert Lendis"
      topSection
      backgroundImage={process_image}
      content={[
        {
          title: 'Anfrage',
          description:
            'Sie nennen uns Ihre Bürogröße, Mitarbeiterzahl und Vorstellungen – wir erstellen Ihnen ein persönliches Angebot',
          image: 'website/Anfrage_rovlsk.svg',
          imageSize: '51',
          width: '3',
          className: '',
          numberTitle: '01',
        },
        {
          title: 'Auftrag',
          description:
            'Wählen Sie Ihre Wunschausstattung und entscheiden Sie sich für eine Mietlaufzeit über 3–36 Monate',
          image: 'website/Auftrag_hr7j28.svg',
          imageSize: '51',
          width: '3',
          className: '',
          numberTitle: '02',
        },
        {
          title: 'Lieferung',
          description:
            'Sie erhalten Ihre Bestellung schon nach kurzer Zeit – Lendis übernimmt die Lieferung und Montage',
          image: 'website/vertical/truck_witawn.png',
          imageSize: '55',
          width: '3',
          className: '',
          numberTitle: '03',
        },
      ]}
    />
  )
}

export default ProcessSection
