import React from 'react'

const Title = props => {
  const {
    title,
    header,
    bgDark = false,
    right = false,
    className = '',
    titleSize = 'text-3xl lg:text-5xl',
    greenTitle = false,
    bgColor = false,
    topMargin = true,
  } = props

  return (
    <div className={`${topMargin ? 'pt-16' : ''} ${className}`}>
      <div className={`relative flex items-center ${right && 'flex-row-reverse'}`}>
        <p
          className={`z-10 font-semibold text-lendis-main uppercase ${
            bgColor ? bgColor : 'bg-white'
          } ${right ? 'text-right pl-6 ml-6' : 'text-left pr-6 mr-6'}`}
        >
          {header}
        </p>
        <hr className="absolute border-t-2 container mx-auto w-full z-0" />
      </div>
      {title && (
        <div>
          <h2
            className={`font-semibold py-6 ${titleSize} ${right && 'text-right'} ${bgDark &&
              'text-white'}`}
          >
            {title}
            {greenTitle && (
              <span>
                <br />
                <span className="text-lendis-main">{greenTitle}</span>
              </span>
            )}
          </h2>
        </div>
      )}
    </div>
  )
}

export default Title
