import React from 'react'
import SquareBox from './SquareBox'
import Title from './Title'

const SquareSection = props => {
  const {
    smallTitle,
    title,
    content,
    backgroundImage = '',
    topSection = false,
    coverImage = true,
  } = props

  return (
    <div
      className={
        topSection
          ? (coverImage ? 'cover-background-image' : 'big-background-image') +
            ` bg-center bg-lendis-main bg-no-repeat bg-top pt-64 px-3 pb-10`
          : ''
      }
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="xl:px-12 bg-white container mx-auto">
        <Title header={smallTitle} title={title} className="mx-8" />
        <div className="pb-10 ">
          <div className="pb-6 lg:flex flex-wrap">
            {content.map((item, idx) => {
              return <SquareBox key={idx} {...item} />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SquareSection
