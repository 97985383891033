import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import MainContactForm from '../MainContactForm'
import Image from '../Lendis/Image'

const TopHome = () => {
  const showVideoPlayer = false
  const [videoModal, setVideoModal] = useState(false)
  const videoModalPopUp = () => {
    setVideoModal(!videoModal)
  }
  return (
    <>
      {videoModal && (
        <div className="bg-lendis-light-gray flex-wrap fixed flex h-full items-center justify-center text-center w-full z-30 top-0">
          <div className="container flex flex-wrap h-full justify-center w-full">
            <div className="h-full w-full ">
              <div className="h-8 w-full text-right">
                <button className="cursor-pointer mb-2/3 ml-12 text-3xl" onClick={videoModalPopUp}>
                  X
                </button>
              </div>
              <ReactPlayer
                className=""
                width="100%"
                height="100%"
                url="https://vimeo.com/103684916"
                controls
                // light={Hero}
                playing
              />
            </div>
          </div>
        </div>
      )}
      <div className="sm:bg-lendis-gray-gradient md:mb-16 pt-16 md:pt-32">
        <div className="container md:flex md:flex-wrap mx-auto md:items-center">
          <div className="bg-lendis-gray-gradient pl-8 pr-8 pt-16 md:w-5/12 z-10">
            <h1 className="font-semibold leading-tight text-4xl mb-4">
              Büroausstattung clever mieten
            </h1>
            <p className="leading-loose">
              Büroeinrichtung, Elektronik & Services für Ihr Unternehmen
              <br />
              #WePowerYourOffice
            </p>
            <MainContactForm formTarget={process.env.FORM_WEBHOOK_URL} />
          </div>

          <div className="flex items-center justify-center relative text-center md:w-7/12 z-10 md:pt-20 md:pb-16">
            <div className="relative">
              <Image src="website/63__DSC5489_ippxoa.jpg" size="2/3" alt="Hero" />
            </div>
            {showVideoPlayer && (
              <div
                role="button"
                tabIndex="-1"
                className="absolute bg-lendis-opacity-50 cursor-pointer flex h-12 items-center justify-center rounded-full text-center w-12"
                onKeyPress={videoModalPopUp}
                onClick={videoModalPopUp}
              >
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 2.0718L13 9L1 15.9282L1 2.0718Z" stroke="white" strokeWidth="2" />
                </svg>
              </div>
            )}
          </div>
        </div>
        <div className=" flex justify-end md:absolute md:float-right md:w-full z-0 md:top-0">
          <div className="bg-lendis-main h-20 w-1/2 md:h-256" />
        </div>
      </div>
    </>
  )
}

export default TopHome
